import { CreditProductT } from '@brainysoft/lk-components';
import { QueryClient, useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { creditProductService } from '@brainysoft/lk-components';

export const useCreditProducts: (enabled?: boolean) => UseQueryResult<CreditProductT[]> = (enabled = true) => {
  return useQuery(
    ['creditProducts'],
    () => {
      return creditProductService.data() as Promise<CreditProductT[]>;
    },
    {
      staleTime: Infinity,
      enabled,
    }
  );
};

export const refreshCreditProducts: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries('creditProducts');
};
