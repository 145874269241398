import { useQuery } from 'react-query';
import { STATUS_CHECK_INTERVAL } from '../config';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { AppContext, creditService } from '@brainysoft/lk-components';
import { useEffect, useState } from 'react';

type CanCreateResulT = {
  canCreate: boolean;
  activeCreditStatus: string | null;
  processUuid: string | null;
};

export const useCanCreate: () => UseQueryResult<CanCreateResulT> = () => {
  const { state } = AppContext.useContainer();
  const [options, setOptions] = useState<
    Omit<UseQueryOptions<unknown, unknown, CanCreateResulT, any>, 'queryKey' | 'queryFn'>
  >({
    refetchInterval: STATUS_CHECK_INTERVAL,
  });

  useEffect(() => {
    if (state.activePage === 'createLoanApp' || state.activePage === 'editLoanApp') {
      setOptions({
        refetchInterval: false,
        refetchOnWindowFocus: true,
      });
    } else {
      setOptions({
        refetchInterval: STATUS_CHECK_INTERVAL,
      });
    }
  }, [state.activePage]);

  return useQuery(
    ['canCreate'],
    () => {
      return creditService.canCreate() as Promise<boolean>;
    },
    options
  );
};
