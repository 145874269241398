import { QueryClient, useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';
import { CreditT } from '@brainysoft/lk-components';
import { REFRESH_DATA_INTERVAL } from '../config';
import { creditService } from '@brainysoft/lk-components';

export const useCredits: () => UseQueryResult<CreditT[]> = () => {
  return useQuery(
    ['credits'],
    () => {
      return creditService.all() as Promise<CreditT[]>;
    },
    {
      refetchInterval: REFRESH_DATA_INTERVAL,
    }
  );
};

export const refreshCredits: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.invalidateQueries('credits');
};

export const resetCredits: (queryClient: QueryClient) => void = (queryClient) => {
  queryClient.resetQueries('credits');
};
