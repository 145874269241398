import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import { Button } from '../../Button';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import Link from 'next/link';
import { useTranslation } from '../../../utils/useTranslation';
import { CustomerSurveys } from '../../Surveys/CustomerSurveys';
import { useSlotSurveys } from '../../../utils/useSlotSurveys';
import { getCustomComponents } from '../../../utils/getCustomComponents';

export const DocumentsCheck: React.FC<IActiveCreditStatus> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const iconSize = 60;

  const slotName = `loanAppWaiting`;
  const slotSurveys = useSlotSurveys(slotName);

  const ActiveCreditInfo = getCustomComponents('ActiveCreditInfo');

  return (
    <div className={`${classes.wrapper} inWidget`}>
      <h2 className={classes.header}>{t('active:statuses.manualCheck.header')}</h2>
      <ResultIcon result={ResultType.WAITING} size={iconSize} />

      {!!slotSurveys.length && (
        <React.Fragment>
          <div>
            Обычно проверки занимают несколько минут. Мы уведомим Вас как только примем решение. А пока ответьте на
            несколько вопросов:
          </div>
          <CustomerSurveys surveys={slotSurveys} slot={slotName} />
        </React.Fragment>
      )}

      {!slotSurveys.length && <p className={classes.descriptor}>{t('active:statuses.waiting.descriptor')}</p>}
    </div>
  );
};
