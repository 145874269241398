import { SurveyT } from '@brainysoft/lk-components';
import { useSurveys } from '../queries/useSurveys';

export const useSlotSurveys: (slotName: string) => SurveyT[] = (slotName) => {
  const surveysQuery = useSurveys();
  const surveys = surveysQuery.data || [];

  return surveys.filter((survey) => {
    return survey.slots.includes(slotName);
  });
};
