import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { useTranslation } from '../../../utils/useTranslation';

export const Approved: React.FC<IActiveCreditStatus> = (props) => {
  const { t } = useTranslation();
  const classes = useThemeStyles('ActiveCreditPage');

  const { credit } = props;

  const iconSize = 60;

  const descriptorText = credit?.data?.gettingMoneyMethodType
    ? t(`active:statuses.approved.${credit?.data?.gettingMoneyMethodType}`)
    : t('active:statuses.approved.undefinedGettingMoneyMethod');

  return (
    <div className={`${classes.wrapper} inWidget`}>
      <h2 className={classes.header}>{t('active:statuses.approved.header')}!</h2>
      <ResultIcon result={ResultType.SUCCESS} size={iconSize} />
      <p className={classes.descriptor}>{descriptorText}</p>
    </div>
  );
};
