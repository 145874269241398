import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import Link from 'next/link';
import { Button } from '../../Button';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { cacheService as Storage } from '@brainysoft/lk-components';
import { ProcessType } from '@brainysoft/lk-components';
import { useTranslation } from '../../../utils/useTranslation';
import { getCustomComponents } from '../../../utils/getCustomComponents';

export const Continue: React.FC<IActiveCreditStatus> = (props) => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const { credit } = props;

  const iconSize = 60;

  React.useEffect(() => {
    const setStorage = async () => {
      await Storage.setItem(`process:${ProcessType.LOAN_APP}:uuid`, credit?.process?.uuid);
      await Storage.setItem(`process:${ProcessType.LOAN_APP}:session`, credit?.process?.session);
    };
    setStorage();
  }, []);

  const ActiveCreditInfo = getCustomComponents('ActiveCreditInfo');

  return (
    <div className={`${classes.wrapper} inWidget`}>
      <h2 className={classes.header}>{t('active:statuses.continue.header')}</h2>
      <ResultIcon result={ResultType.WAITING} size={iconSize} />
      <p className={classes.descriptor}>{t('active:statuses.continue.descriptor')}?</p>

      <Link href={'/loan-app/edit'} passHref>
        <Button size={'large'} type={'primary'} className={classes.homeButton}>
          {t('active:statuses.continue.continueCheckout')}
        </Button>
      </Link>
    </div>
  );
};
