import * as React from 'react';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '@brainysoft/lk-custom-kernel/utils/use-theme-styles';
import { useTranslation } from '../../../utils/useTranslation';
import { ResultType } from '@brainysoft/lk-components';
import { ResultIcon } from '../../ResultIcon';
import Link from 'next/link';
import { Button } from '../../Button';

const interactionInitUrl = '/contracts/upload-passport/init';

export const InteractionUploadPassport: React.FC<IActiveCreditStatus> = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const iconSize = 60;

  return (
    <div className={classes.wrapper}>
      <h2 className={classes.header}>{t('active:statuses.uploadPassport.header')}</h2>
      <ResultIcon result={ResultType.WAITING} size={iconSize} />
      <Link href={interactionInitUrl} passHref>
        <Button size={'large'} type={'primary'} className={classes.homeButton}>
          {t('active:statuses.uploadPassport.more')}
        </Button>
      </Link>
    </div>
  );
};
