import React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { ResultType, SurveyFormDataT, SurveyFormResultT, surveysService, SurveyT } from '@brainysoft/lk-components';
import { useSurveyCustomComponent } from '../../utils/useSurveyCustomComponent';
import { SurveyFormProps } from './SurveyForm';
import { Form, Formik, FormikProps } from 'formik';
import { Button } from '../Button';
import { useQueryClient } from 'react-query';
import { refreshSurveys } from '../../queries/useSurveys';
import { ResultIcon } from '../ResultIcon';
import { FormikForm } from '../../packages/Formik/Components/FormikForm';
import { get } from 'lodash';

interface CustomerSurveysProps {
  surveys: SurveyT[];
  slot: string;
}

export const CustomerSurveys: React.FC<CustomerSurveysProps> = (props) => {
  const classes = useThemeStyles('Survey');
  const queryClient = useQueryClient();

  const { surveys, slot } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [answered, setAnswered] = React.useState<string[]>([]);

  const onUpdate = (formik, surveyName) => {
    const answer = get(formik, `values.surveys.${surveyName}.answer`);
    setIsDisabled(!answer);
  };

  const onSubmit = async (surveyName: string, values: SurveyFormDataT) => {
    setIsLoading(true);
    setIsDisabled(true);
    const survey = values.surveys[surveyName] || ({} as SurveyFormResultT);
    const { id, ...surveyData } = survey;

    if (!id || !surveyData?.answer || !surveyData?.slot) return;

    try {
      await surveysService.result(id, surveyData);
    } catch (err) {
      console.log(err);
      return;
    }

    setAnswered([...answered, surveyName]);
    setIsLoading(false);
    setIsDisabled(false);
    await setTimeout(() => {
      void refreshSurveys(queryClient);
    }, 3000);
  };

  return (
    <div className={classes.surveysWrapper}>
      {surveys.map((survey, index) => {
        return (
          <div className={classes.surveyWrapper} key={index}>
            {!answered.includes(survey.name) && (
              <Formik
                initialValues={{ surveys: { [survey.name]: { id: undefined, answer: undefined } } }}
                onSubmit={(values) => onSubmit(survey.name, values)}
              >
                {(formik: FormikProps<any>) => (
                  <FormikForm
                    formik={formik}
                    onSubmit={formik.handleSubmit}
                    onUpdate={(formik) => onUpdate(formik, survey.name)}
                  >
                    <_SurveyForm survey={survey} slot={slot} type={'narrow'} key={index} />
                    <div className={classes.submitButton}>
                      <Button type={'primary'} htmlType={'submit'} loading={isLoading} disabled={isDisabled}>
                        Ответить
                      </Button>
                    </div>
                  </FormikForm>
                )}
              </Formik>
            )}
            {answered.includes(survey.name) && (
              <div className={classes.surveyResult}>
                <ResultIcon result={ResultType.SUCCESS} size={40} />
                <h5>Спасибо за Ваш ответ!</h5>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const _SurveyForm: React.FC<SurveyFormProps> = (props) => {
  const { survey } = props;
  const SurveyForm = useSurveyCustomComponent<SurveyFormProps>(survey.name);
  return <SurveyForm {...props} />;
};
