import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { useTranslation } from '../../utils/useTranslation';
import { InterfaceModules, ProcessFormsType } from '@brainysoft/lk-components';
import { useCreditProducts } from '../../queries/useCreditProduct';
import { Message } from '../Message';
import { useInterfaceModuleSetting } from '../../utils/useInterfaceModuleSetting';
import { LoadingSpinner } from '../LoadingSpinner';
import { getCustomComponents } from '../../utils/getCustomComponents';

export const LoanAppCreateWidget: React.FC = () => {
  const classes = useThemeStyles('LoanAppCreateWidget');
  const layoutClasses = useThemeStyles('Layout');
  const { t, keyExists } = useTranslation();

  const LoanAppCreateWidget = getCustomComponents('LoanAppCreateWidget');

  const creditProductsQuery = useCreditProducts();
  const widgetSettings = useInterfaceModuleSetting(InterfaceModules.CREATE_LOANAPP_WIDGET);
  const isLoading = creditProductsQuery.isLoading;

  const creditProducts = creditProductsQuery.data ?? [];

  return (
    <React.Fragment>
      <div className={classes.leadWrapper}>
        <div className={classes.headerWrapper}>
          <h3 className={`${layoutClasses.widgetTitle} ${classes.header}`}>{t('widgets:loanAppCreate.header')}</h3>
          {keyExists('widgets:loanAppCreate.subheader') && (
            <div className={classes.subheader}>{t('widgets:loanAppCreate.subheader')}</div>
          )}
        </div>
        {isLoading && (
          <div className={classes.formPlaceholder}>
            <LoadingSpinner size={8} margin={4} />
          </div>
        )}
        {!isLoading && !creditProducts.length && (
          <div className={classes.unavalable}>
            <Message type={'error'}>{t('widgets:loanAppCreate.unavailable')}</Message>
          </div>
        )}
        {/*{!isLoading && !!creditProducts.length && <CreateWidgetForm widgetSettings={widgetSettings} creditProducts={creditProducts} />}*/}
        {!isLoading && !!creditProducts.length && (
          <LoanAppCreateWidget widgetSettings={widgetSettings} creditProducts={creditProducts} />
        )}
      </div>
    </React.Fragment>
  );
};
