import * as React from 'react';
import { ResultIcon } from '../../ResultIcon';
import { ResultType } from '@brainysoft/lk-components';
import { IActiveCreditStatus } from '../ActiveCreditSelector';
import { useThemeStyles } from '../../../utils/useThemeStyles';
import { useTranslation } from '../../../utils/useTranslation';

export const Issued: React.FC<IActiveCreditStatus> = () => {
  const classes = useThemeStyles('ActiveCreditPage');
  const { t } = useTranslation();

  const iconSize = 60;

  return (
    <div className={`${classes.wrapper} inWidget`}>
      <h2 className={classes.header}>{t('active:statuses.issued.header')}!</h2>
      <ResultIcon result={ResultType.SUCCESS} size={iconSize} />
      <p className={classes.descriptor}>{t('active:statuses.issued.descriptor')}.</p>
    </div>
  );
};
