import * as React from 'react';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { ActiveCreditStatusLoading } from './ActiveCreditStatusLoading';
import { ActiveCreditSelector } from './ActiveCreditSelector';
import { useActiveCredit } from '../../queries/useActiveCredit';

export const ActiveCreditWidget: React.FC = () => {
  const layoutStyles = useThemeStyles('Layout');
  const classes = useThemeStyles('ActiveCreditPage');

  const activeCredit = useActiveCredit();

  return (
    <React.Fragment>
      <div className={layoutStyles.widgetWrapper}>
        <div className={classes.activeCreditWidgetWrapper}>
          {activeCredit.isLoading && <ActiveCreditStatusLoading />}
          {!activeCredit.isLoading && activeCredit.data && <ActiveCreditSelector credit={activeCredit.data} />}
        </div>
      </div>
    </React.Fragment>
  );
};
