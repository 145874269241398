import React from 'react';
import { default as customComponents } from '../customComponents/active';
import { getCustomComponents } from './getCustomComponents';

export const useSurveyCustomComponent: <T>(surveyName: string) => React.FC<T> = (surveyName) => {
  const _surveyName = surveyName || '';
  const surveyRendererName = 'SurveyForm' + _surveyName.charAt(0).toUpperCase() + _surveyName.slice(1);

  const DefaultSurveyForm = getCustomComponents('SurveyForm');

  return customComponents[surveyRendererName] || DefaultSurveyForm;
};
