import * as React from 'react';
import { AppContext } from '@brainysoft/lk-components';

export const useActivePage = (page: string) => {
  const { methods } = AppContext.useContainer();

  React.useEffect(() => {
    methods.setActivePage(page);
  }, []);
};
