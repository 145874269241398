import * as React from 'react';
import Logo from '../Logo';
import { useRouter } from 'next/router';
import { HEADER_INVERTED } from '../../config';
import { useThemeStyles } from '../../utils/useThemeStyles';
import { getCustomComponents } from '../../utils/getCustomComponents';

export interface ILogoObject {
  auth?: string;
  normal?: string;
  inverted?: string;
}

export const Header: React.FC = () => {
  const classes = useThemeStyles('Header');
  const router = useRouter();

  const onClick = () => {
    router.push('/');
  };

  const HeaderPhone = getCustomComponents('HeaderPhone');
  const Menu = getCustomComponents('Menu');

  const invertedClass = HEADER_INVERTED ? 'inverted' : '';

  return (
    <header className={`${classes.header} ${invertedClass}`}>
      <div className={classes.headerWrap}>
        <Logo inverted={!HEADER_INVERTED} onClick={onClick} />

        <div className={classes.phoneMenuWrapper}>
          <HeaderPhone inside={true} inverted={HEADER_INVERTED} />
          {/*{props.telephone.formatted && (*/}
          {/*    <div className={classes.phoneWrap}>*/}
          {/*        <a className={`${classes.phoneNumber} ${invertedClass}`} href={`tel:+${props.telephone.raw}`}>*/}
          {/*            {props.telephone.formatted ? props.telephone.formatted : ''}*/}
          {/*        </a>*/}
          {/*        <span className={`${classes.phoneDetail} ${invertedClass}`}>{description}</span>*/}
          {/*    </div>*/}
          {/*)}*/}
          <Menu />
        </div>
      </div>
    </header>
  );
};
