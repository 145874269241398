import * as React from 'react';
import { CreditStatusType, CreditInteractionsType } from '@brainysoft/lk-components';
import { Approved } from './Statuses/Approved';
import { InteractionUploadPassport } from './Statuses/InteractionUploadPassport';
import { IsError } from './Statuses/IsError';
import { Waiting } from './Statuses/Waiting';
import { Continue } from './Statuses/Continue';
import { Issued } from './Statuses/Issued';
import { ActiveCreditStatusLoading } from './ActiveCreditStatusLoading';
import { CreditT } from '@brainysoft/lk-components';
import { useCanCreate } from '../../queries/useCanCreate';
import { LoanAppCreateWidget } from '../LoanAppCreateWidget';
import { Message } from '../Message';
import { useTranslation } from '../../utils/useTranslation';
import { DocumentsCheck } from './Statuses/DocumentsCheck';
import { getCustomComponents } from '../../utils/getCustomComponents';

export interface IActiveCreditStatus {
  credit: CreditT;
}

export const ActiveCreditSelector: React.FC<IActiveCreditStatus> = (props) => {
  const { credit } = props;

  const canCreateQuery = useCanCreate();
  const { t } = useTranslation();

  let component = () => <Message type={'info'}>{t('active:message.info')}</Message>;
  const Denied = getCustomComponents('Denied');
  const InteractionSignOffer = getCustomComponents('InteractionSignOffer');

  if (canCreateQuery.data) {
    component = () => {
      if (canCreateQuery.data?.canCreate) {
        return <LoanAppCreateWidget />;
      } else if (credit.interaction === CreditInteractionsType.SIGN_OFFER) {
        return <InteractionSignOffer {...props} />;
      } else if (credit.interaction === CreditInteractionsType.UPLOAD_PASSPORT) {
        return <InteractionUploadPassport {...props} />;
      } else if (credit.status === CreditStatusType.ISSUED) {
        return <Issued {...props} />;
      } else if (credit.status === CreditStatusType.APPROVED) {
        return <Approved {...props} />;
      } else if (credit.status === CreditStatusType.CHECK) {
        return <Waiting {...props} />;
      } else if (credit.status === CreditStatusType.TECH_FAULT) {
        return <IsError {...props} />;
      } else if (credit.status === CreditStatusType.NEW) {
        return <Continue {...props} />;
      } else if (credit.status === CreditStatusType.WAITING) {
        return <Waiting {...props} />;
      } else if (credit.status === CreditStatusType.MANUAL_CHECK) {
        return <DocumentsCheck {...props} />;
      } else if (credit.status === CreditStatusType.DENIED) {
        return <Denied {...props} />;
      }
      return <Message type={'info'}>{t('active:message.info')}</Message>;
    };
  }

  return (
    <React.Fragment>
      {canCreateQuery.isLoading && <ActiveCreditStatusLoading />}
      {!canCreateQuery.isLoading && component()}
    </React.Fragment>
  );
};
